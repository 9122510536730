.button {
	border-radius: $button-radius;
	padding: 1.15rem 3rem;
	font-size: rem(16px);
	margin: 0;
	min-width: 240px;
	background-color: $button-bg;
	color: $button-fg;
	text-align: center;
	transition: background-color 0.15s ease, box-shadow 0.15s ease;
	position: relative;
	box-shadow: none;
	display: inline-flex;
	justify-content: center;
	align-items: center;

	@include link(&) {
		color: $button-fg;
		text-decoration: none;
	}

	&--footer-button {
		background-color: #0d2433;
	}

	&--forward {
		&::after {
			content: encode-svg(
				'<svg width="14" height="9" viewBox="0 0 14 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><path d="M10.086,3.481l-10.086,-0l0,2l10.125,-0l-1.832,1.831l1.414,1.415l4.233,-4.246l-4.233,-4.208l-1.414,1.415l1.793,1.793Z" style="fill:#fff;"/></svg>'
			);
			transition: transform 0.3s ease-in-out;
			transform: translateX(0);
			display: inline-block;
			margin-left: 12px;
		}

		&:hover::after,
		&:focus::after {
			transform: translateX(0.5rem);
		}
	}

	&:not([disabled]) {
		&:hover,
		&:focus,
		&:active {
			z-index: 3;
			outline: 0;
		}
	}
}
