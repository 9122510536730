/*==============================
	FONTS
==============================*/

$font-monospace: SFMono-Regular, "Roboto Mono", Consolas, "Liberation Mono",
	Menlo, Courier, monospace;

$font-body: "Comfortaa", sans-serif;
$font-headings: "Titillium Web", sans-serif;

/*==============================
	COLORS
==============================*/

$color-base: #7a7a7a;
$color-brand: #00569d;
$color-brand-dark: #192b3c;
$color-footer: #112c3e;
$color-light: #f6f2ef;
$color-dark: #383433;

$color-neutral-100: #f8f8f8;
$color-neutral-200: #f2f2f2;
$color-neutral-300: #e2e2e2;
$color-neutral-400: #cacaca;
$color-neutral-500: #b2b2b2;
$color-neutral-600: #969696;
$color-neutral-700: #7c7c7c;
$color-neutral-800: #545454;
$color-neutral-900: #161616;

/*==============================
	SIZES
==============================*/

$max-width: 1280px !global;
$max-width-wide: 1700px !global;
$grid-spacing: 2.5vmin;
$grid-spacing-fixed: 42px;

/* COMPONENT VARIABLES */

/*==============================
	FORMS
==============================*/

$form-active-color: #6b9aeb;
$form-active-text: #ffffff;

$form-item-standard-size: 52px;
$form-item-xl-size: 64px;
$form-item-border-color: #cccccc;
$form-item-border-width: 1px;
$form-item-border-radius: 4px;
$form-item-bg: #ffffff;
$form-item-fg: #2a2a2a;
$form-disabled-item-border-color: #e3e3e3;
$form-disabled-item-bg: #ebebeb;
$form-disabled-item-fg: lighten($form-item-fg, 10%);
$form-item-focus-shadow: rgba(0, 0, 0, 0.12);
$form-icon-color: #cccccc;

/*==============================
	BUTTONS
==============================*/

$button-radius: 3px;
$button-bg: $color-brand;
$button-fg: white;
