@charset "UTF-8";
/*
* BASE DEPENDENCIES
*/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/
.input {
  transition: border-color .11s ease, box-shadow .21s ease;
  box-shadow: none;
  width: 100%;
  font-size: 1em;
  border-radius: 4px;
  border-color: #cccccc;
  border-style: solid;
  border-width: 1px;
  background-color: #ffffff;
  color: #2a2a2a;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input:focus {
  border-color: #6b9aeb;
  box-shadow: 0 8px 26px rgba(0, 0, 0, 0.12);
  outline: 0;
}

.input[disabled] {
  border-color: #e3e3e3;
  background-color: #ebebeb;
  color: #444444;
  cursor: not-allowed;
}

/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/
/*
* LAYOUT
*/
.button {
  border-radius: 3px;
  padding: 1.15rem 3rem;
  font-size: 1rem;
  margin: 0;
  min-width: 240px;
  background-color: #00569d;
  color: white;
  text-align: center;
  transition: background-color 0.15s ease, box-shadow 0.15s ease;
  position: relative;
  box-shadow: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.button, .button:link, .button:visited {
  color: white;
  text-decoration: none;
}

.button--footer-button {
  background-color: #0d2433;
}

.button--forward::after {
  content: url("data:image/svg+xml;utf8,%3Csvg width='14' height='9' viewBox='0 0 14 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cpath d='M10.086,3.481l-10.086,-0l0,2l10.125,-0l-1.832,1.831l1.414,1.415l4.233,-4.246l-4.233,-4.208l-1.414,1.415l1.793,1.793Z' style='fill:%23fff;'/%3E%3C/svg%3E");
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  display: inline-block;
  margin-left: 12px;
}

.button--forward:hover::after, .button--forward:focus::after {
  transform: translateX(0.5rem);
}

.button:not([disabled]):hover, .button:not([disabled]):focus, .button:not([disabled]):active {
  z-index: 3;
  outline: 0;
}

.input {
  min-height: 52px;
  padding: .3em 1em;
}

.input.-main {
  min-height: 64px;
  font-size: 1.15em;
}

.form {
  padding: 0;
  margin: 0 auto 2em;
  display: grid;
  grid-gap: 2rem;
  max-width: 80rem;
  /* &__fieldset {
		grid-column: span 2;
		padding: .85em 1.5em .625em;
		border: 1px $form-item-border-color solid;
		border-radius: $form-item-border-radius;
	}

	&__legend {
		padding: 0 .5em;
		font-size: 1.35rem;
		font-weight: bold;
	} */
}

@media screen and (min-width: 45rem) {
  .form {
    grid-template-columns: auto auto;
  }
}

.form__section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media screen and (min-width: 40rem) {
  .form__section.-full {
    grid-column: span 2;
  }
}

.form__section.-to-center {
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-column: 1 / -1;
}

.form__label {
  display: block;
  margin-bottom: .4em;
  cursor: default;
}

.form__message {
  font-weight: bold;
}

.form__message.-success  {
  color: #54b351;
}

.form__message.-error {
  color: #fc4b3f;
}
